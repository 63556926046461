import { useAppSelector } from "../../../hooks/useAppSelector";
import { AGORA_ID } from "../../../config/env";
import "../styles/index.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import { RtmChannel, RtmClient } from "agora-rtm-react";
import * as AgoraRTM from "agora-rtm-react";
import { useCallActions } from "../../../hooks/useReduxActions";
import { CallUser } from "../../../interfaces/Call";
import SelectMedia from "../components/SelectMedia";
import CallRightSidebar from "../components/CallRightSidebar/CallRightSidebar";
import CallLeftContent from "../components/CallLeftContent/CallLeftContent";
import { CallEmpty } from "./";
import { IUserCallActions } from "../interfaces/IUserCallActions";
import { ILocalVideoTrack } from "agora-rtc-react";
import { Appointment } from "../../../interfaces/Appointment";
import useApi from "../../../hooks/useApi";
import appointmentService from "../../../services/AppointmentService/AppointmentService";
import { Profile_Interface } from "../../../interfaces/User";

const useRtmClient = AgoraRTM.createClient(AGORA_ID);

export interface IRtmClient {
  client: RtmClient | null;
  channel: RtmChannel | null;
}

export const CallHome = () => {
  const { data, users, start, selectedAppointment } = useAppSelector(
    (state) => state.callReducer
  );
  const { profile_data } = useAppSelector((state) => state.authReducer);

  const {
    setCurrentCallSession,
    addCallUser,
    startCall,
    clearCallUsers,
    updateCallUsers,
    shareScreen,
    addMessage,
    setSelectedAppointment,
  } = useCallActions();

  const usersRef = useRef(users);

  const rtcClient = useRef<IAgoraRTCClient | null>(null);
  const localAudioTrack = useRef<IMicrophoneAudioTrack | null>(null);
  const localVideoTrack = useRef<ICameraVideoTrack | null>(null);
  const screenVideoTrack = useRef<ILocalVideoTrack | null>(null);
  const admin = useRef(false);
  const mainRef = useRef<HTMLVideoElement>(null);

  const rtmChannelName = `${data?.channelName}-cmds`;

  //Contains the RTM data
  const rtmClient = useRef<IRtmClient>({
    client: null,
    channel: null,
  });

  rtmClient.current.client = useRtmClient();

  const runVideoCallApplication = async () => {
    if (!rtcClient.current) {
      rtcClient.current = AgoraRTC.createClient({
        mode: "rtc",
        codec: "h264",
      });
      AgoraRTC.setLogLevel(4);

      initClientEvents();
    }

    if (data) {
      if (!localVideoTrack.current)
        localVideoTrack.current = await AgoraRTC.createCameraVideoTrack();

      if (!localAudioTrack.current)
        localAudioTrack.current = await AgoraRTC.createMicrophoneAudioTrack();
    }
  };

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  useEffect(() => {
    runVideoCallApplication();
  }, [data]);

  const _updateUsers = (val: CallUser[]) => {
    usersRef.current = val;
    updateCallUsers(val);
  };

  const initClientEvents = () => {
    rtcClient.current?.on("user-joined", async (user) => {
      // A remote user has joined, add him to the users state
      addCallUser({ uid: user.uid?.toString() });
    });

    rtcClient.current?.on("user-published", async (user, type) => {
      await rtcClient.current?.subscribe(user, type);

      const result = usersRef.current.map((User) => {
        if (user.uid === User.uid) {
          if (type === "video") {
            return {
              ...User,
              video: user.hasVideo,
              videoTrack: user.videoTrack,
            };
          }

          if (type === "audio") {
            user.audioTrack?.play();

            return {
              ...User,
              audio: user.hasAudio,
              audioTrack: user.audioTrack,
            };
          }
        }

        return User;
      });

      _updateUsers(result);
    });

    rtcClient.current?.on("user-left", async (user) => {
      if (rtmClient.current.channel) {
        let usrs = await rtmClient.current.client?.getChannelAttributes(
          rtmClient.current.channel.channelId
        );
      }

      const result = usersRef.current.filter((User) => User.uid !== user.uid);

      _updateUsers(result);
    });

    rtcClient.current?.on("user-unpublished", (user, type) => {
      const result = usersRef.current.map((User) => {
        if (User.uid === user.uid) {
          if (type === "audio") {
            return { ...User, audio: user.hasAudio };
          } else if (type === "video") {
            return { ...User, video: user.hasVideo };
          }
        }
        return User;
      });

      _updateUsers(result);
    });
  };

  const initRtmEvents = () => {
    rtmClient.current.client?.on(
      "MessageFromPeer",
      async (message: any, memberId: any) => {
        let type = message.text;
        if (type === "audio" || type === "video") {
          action(type);
        } else if (type === "kick") {
          action("leave");
        }
      }
    );

    rtmClient.current.channel?.on("AttributesUpdated", (attr) => {
      const result = usersRef.current.map((User) => {
        if (User.username) {
          if (attr[User.uid]) {
            const usr = JSON.parse(attr[User.uid].value);
            return { ...User, ...usr };
          } else {
            return User;
          }
        } else {
          let usr = JSON.parse(attr[User.uid].value);
          return {
            ...User,
            username: usr.username,
            admin: usr.admin,
            client: usr.client,
            name: usr.name,
          };
        }
      });

      _updateUsers(result);
    });

    rtmClient.current.channel?.on(
      "ChannelMessage",
      (message: any, senderId) => {
        addMessage({ message: message.text, senderId });
      }
    );
  };

  const init = async () => {
    try {
      if (
        !data ||
        !rtcClient.current ||
        !localVideoTrack.current ||
        !localAudioTrack.current
      ) {
        return;
      }

      if (mainRef.current) localVideoTrack.current.play(mainRef.current);

      let obj: { [key: string]: string } = {};

      const uid = await rtcClient.current.join(
        AGORA_ID,
        data.channelName,
        data.token,
        profile_data.email
      );

      const tracks: (ICameraVideoTrack | IMicrophoneAudioTrack)[] = [
        localVideoTrack.current,
        localAudioTrack.current,
      ];

      await rtcClient.current.publish(tracks);

      obj[`${uid}`] = JSON.stringify({
        uid: uid,
        username: profile_data.email,
        name: `${profile_data.given_name} ${profile_data.family_name}`,
        admin: profile_data?.role === "TEACHER",
        client: profile_data?.role === "STUDENT",
      });

      admin.current = profile_data?.role === "TEACHER";

      // Adding a User to the Users State
      addCallUser({
        uid: uid?.toString(),
        audio: true,
        video: true,
        screen: profile_data?.role === "TEACHER",
        client: profile_data?.role === "STUDENT",
        videoTrack: localVideoTrack.current,
        admin: profile_data?.role === "TEACHER",
        username: profile_data?.email,
      });

      //Publishing your Streams
      startCall(true);

      await rtmClient.current.client?.login({
        uid: profile_data?.email,
        token: data.rtmToken,
      });

      rtmClient.current.channel =
        rtmClient.current.client?.createChannel(rtmChannelName) || null;

      const joined = await rtmClient.current.channel?.join();
      console.log("[JOINED]", joined);

      initRtmEvents();

      await rtmClient.current.client?.addOrUpdateChannelAttributes(
        rtmChannelName,
        obj,
        { enableNotificationToChannelMembers: true }
      );

      window.addEventListener("beforeunload", async () => {
        if (rtcClient.current?.connectionState === "CONNECTED")
          await rtcClient.current?.leave();
        localVideoTrack.current?.close();
        localAudioTrack.current?.close();
      });
    } catch (error) {}
  };

  const userLeaveMeeting = async () => {
    try {
      // Destroy the local audio and video tracks.
      localAudioTrack.current?.close();
      localVideoTrack.current?.close();
      clearCallUsers();
      startCall(false);
      setCurrentCallSession(null);

      if (rtmClient.current.channel && rtcClient.current?.uid) {
        await rtmClient.current.client?.deleteChannelAttributesByKeys(
          rtmClient.current.channel.channelId,
          [rtcClient.current.uid?.toString()]
        );
      }

      if (rtcClient.current?.connectionState === "CONNECTED") {
        rtcClient.current?.removeAllListeners();
        await rtcClient.current?.leave();
      }

      await rtmClient.current.channel?.leave();
      await rtmClient.current.client?.logout();
    } catch (error: any) {
      console.log("[LEAVE ERROR]", error);
    } finally {
      window.location.reload();
    }
  };

  const toggleUserGraphicState = (type: "audio" | "video", user?: CallUser) => {
    if (user && admin.current) {
      if (!rtmClient.current.client || !rtmClient.current.channel) {
        return;
      }

      try {
        rtmClient.current.client.sendMessageToPeer({ text: type }, user.uid);
      } catch (error) {
        console.log(error);
      }

      const result = usersRef.current.map((item) => {
        if (item.uid === user.uid) {
          if (type === "audio") {
            return { ...item, audio: !item.audio };
          }

          if (type === "video") {
            return { ...item, video: !item.video };
          }
        }
        return item;
      });

      _updateUsers(result);
    } else {
      const result = usersRef.current.map((user) => {
        if (user.uid === profile_data.email) {
          if (type === "audio") {
            localAudioTrack.current?.setEnabled(!user.audio);
            return { ...user, audio: !user.audio };
          }

          if (type === "video") {
            localVideoTrack.current?.setEnabled(!user.video);
            return { ...user, video: !user.video };
          }
        }
        return user;
      });

      _updateUsers(result);
    }
  };

  const createScreenTrack = async () => {
    if (!screenVideoTrack.current)
      screenVideoTrack.current = await AgoraRTC.createScreenVideoTrack(
        {
          encoderConfig: {
            width: 1920,
            height: 1080,
            frameRate: 15,
            bitrateMax: 1000,
            bitrateMin: 0,
          },
          optimizationMode: "motion" as "motion" | "detail",
          screenSourceType: "screen",
        },
        "disable"
      );
  };

  const startScreenShare = async () => {
    try {
      // Create a screen track for screen sharing.
      await createScreenTrack();

      if (!screenVideoTrack.current) {
        return;
      }

      // Replace the video track with the screen track.
      await localVideoTrack.current?.replaceTrack(
        screenVideoTrack.current.getMediaStreamTrack(),
        true
      );

      shareScreen(true);
    } catch (error) {
      console.error("[ERROR]", error);
    }
  };

  const endScreenShare = async () => {
    try {
      // Replace the screen track with the local video track.
      const newVideoTrack = await AgoraRTC.createCameraVideoTrack();

      await localVideoTrack.current?.replaceTrack(
        newVideoTrack.getMediaStreamTrack(),
        true
      );

      screenVideoTrack.current?.stop();
      screenVideoTrack.current?.close();

      screenVideoTrack.current = null;

      shareScreen(false);
    } catch (error: any) {
      console.log("[ERROR]", error);
    }
  };

  // Sending a Message
  const sendUserMessage = async (text: string) => {
    if (!rtmClient.current.client || !rtmClient.current.channel) {
      return;
    }

    try {
      await rtmClient.current.channel.sendMessage({
        text,
      });

      addMessage({ message: text, senderId: profile_data.email });
    } catch (error) {
      console.log("[ERROR]", error);
    }
  };

  const action = async (
    action: IUserCallActions,
    payload?: any,
    user?: CallUser
  ) => {
    switch (action) {
      case "leave":
        return userLeaveMeeting();
      case "audio":
        return toggleUserGraphicState("audio", user);
      case "video":
        return toggleUserGraphicState("video", user);
      case "startscreenshare":
        return startScreenShare();
      case "endscreenshare":
        return endScreenShare();
      case "sendmessage":
        return sendUserMessage(payload);
    }
  };

  const fetchAppointmentApi = useApi<Appointment, string>(
    appointmentService.getAppointmentById
  );

  const fetchAppointmentTeacherApi = useApi<Profile_Interface | null, string>(
    appointmentService.getAppointmentTeacher
  );

  useEffect(() => {
    const run = async () => {
      const appointment = await fetchAppointmentApi.request(data!.channelName);
      if (appointment) {
        const teacher = await fetchAppointmentTeacherApi.request(
          appointment.appointmentId,
          false
        );
        if (teacher) appointment.acceptedBy = teacher;
        setSelectedAppointment(appointment);
      }
    };

    if (data && data.channelName) {
      run();
    }
  }, [data?.channelName]);

  const adminUser = useMemo(() => {
    return (
      users.filter(
        (User) =>
          User.admin ||
          (selectedAppointment?.acceptedBy &&
            User.uid === selectedAppointment.acceptedBy.email)
      )[0] || null
    );
  }, [users, selectedAppointment?.acceptedBy?.id]);

  useEffect(() => {
    if (!adminUser) {
      const result = usersRef.current.map((user) => {
        localAudioTrack.current?.setEnabled(false);
        localVideoTrack.current?.setEnabled(false);
        return { ...user, audio: false, video: false };
      });

      _updateUsers(result);
    }
  }, [adminUser]);

  return (
    <div className="ml_call_home animate__animated animate__fadeIn">
      {!data && <CallEmpty />}

      {!start && data && (
        <SelectMedia
          init={init}
          localAudioTrack={localAudioTrack}
          localVideoTrack={localVideoTrack}
        />
      )}

      {start && data && (
        <div className="ml_call_home_container">
          <div className="ml_call_home_container_left">
            <CallLeftContent
              courseName={selectedAppointment?.course!}
              adminUser={adminUser}
              action={action}
            />
          </div>
          <div className="ml_call_home_container_right">
            <CallRightSidebar
              adminUser={adminUser}
              action={action}
              localVideoTrack={localVideoTrack}
            />
          </div>
        </div>
      )}
    </div>
  );
};
