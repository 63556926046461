// Package Imports
import {
  AiOutlineClockCircle,
  AiOutlineClose,
  AiOutlineDelete,
} from "react-icons/ai";
import { MdOutlineCalendarMonth } from "react-icons/md";
import Popover from "@mui/material/Popover";

// Drawer Styles
import "./AppointmentDrawerStyles.scss";

import PopDrawer from "../../../../layouts/DrawerLayout/DrawerLayout";
import Button from "../../../../components/Button/Button";
import { Appointment } from "../../../../interfaces/Appointment";
import { formatDate } from "../../../../utils/convertDateToString";
import { convertUnixTimeStampToTime } from "../../utils/convertUnixTimeStampToTime";
import CopyWithToolTip from "../../../../components/CopyWithToolTip/CopyWithToolTip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useApi from "../../../../hooks/useApi";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useCallActions } from "../../../../hooks/useReduxActions";
import { CallSession } from "../../../../interfaces/Call";
import appointmentService from "../../../../services/AppointmentService/AppointmentService";
import { useNavigate } from "react-router-dom";
import { AllRouteConstants } from "../../../../router/routes";
import CustomAlert from "../../../../components/Alert";

type TAppointmentInformationDrawer = {
  onClose: () => void;
  drawerToggler: boolean;
  appointment: string;
  getTeacher: (id: string) => Promise<void>;
  deleteAppointment: (appointmentId: string) => Promise<boolean>;
  deleting?: boolean;
};

const AppointmentInformationDrawer: React.FC<TAppointmentInformationDrawer> = (
  props
) => {
  const {
    onClose,
    drawerToggler,
    appointment,
    getTeacher,
    deleteAppointment,
    deleting = false,
  } = props;

  const { currentAppointments } = useAppSelector(
    (state) => state.appointmentReducer
  );

  const selectedAppointment = useMemo(() => {
    if (!appointment) return null;
    const selected = currentAppointments.filter((e) => e.id === appointment)[0];

    if (!selected) return null;

    return selected.extendedProps as Appointment;
  }, [appointment, currentAppointments]);

  const {
    course,
    paid,
    appointmentDate,
    isOnDemand,
    dateUpdated,
    topic,
    acceptedBy,
    status,
    comments,
    startTime,
    endTime,
    duration,
    active,
    appointmentId,
    createdBy,
  } = selectedAppointment || ({} as Appointment);

  const navigate = useNavigate();
  const getDuration = () => {
    if (isOnDemand) {
      return duration;
    }

    const durationHour = Math.round(
      Math.abs(startTime - endTime) / (1000 * 60 * 60)
    );

    return durationHour;
  };

  const getAmount = () => {
    return (getDuration() * 10000).toLocaleString("en-US");
  };

  const appointmentStartTime = () => {
    if (!selectedAppointment) return;

    if (isOnDemand) {
      return dateUpdated.slice(11, 16);
    } else {
      return convertUnixTimeStampToTime(selectedAppointment.startTime);
    }
  };

  const appointmentEndTime = () => {
    if (!selectedAppointment) return;

    if (isOnDemand) {
      return `${
        parseInt(dateUpdated.slice(11)) + selectedAppointment.duration
      }:${dateUpdated.slice(14, 16)}`;
    } else {
      return convertUnixTimeStampToTime(selectedAppointment.endTime);
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleDelete = (event: any) => {
    setAnchorEl(event.currentTarget as any);
  };

  const handleClose = () => {
    if (deleting) return;
    setAnchorEl(null);
  };

  const handleConfirmDelete = useCallback(async () => {
    if (!appointmentId) return;

    const result = await deleteAppointment(appointmentId);

    if (result) {
      handleClose();
      onClose();
    }
  }, [appointmentId, deleteAppointment, onClose]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getToken = (id: string) => appointmentService.getAgoraToken(id);

  const { profile_data } = useAppSelector((state) => state.authReducer);
  const getTokenApi = useApi<{ data: CallSession }, string>(getToken);
  const { setCurrentCallSession } = useCallActions();

  const onStartClick: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();
    const result = await getTokenApi.request(appointmentId);

    if (result) {
      setCurrentCallSession(result.data);
      navigate(AllRouteConstants.main.call.index);
    }
  };

  const goToCheckout = () => {
    if (!selectedAppointment) {
      return;
    }

    navigate(AllRouteConstants.main.checkout.index, {
      state: { appointmentId },
    });
  };

  const startShouldShow = useMemo(() => {
    const now = Date.now();

    if (now >= startTime && now < endTime) {
      return true;
    }

    return false;
  }, [startTime, endTime]);

  useEffect(() => {
    if (
      selectedAppointment?.appointmentId &&
      !selectedAppointment?.acceptedBy &&
      profile_data.role === "STUDENT"
    ) {
      getTeacher(selectedAppointment.appointmentId);
    }
  }, [selectedAppointment?.appointmentId]);

  return (
    <PopDrawer onClose={onClose} drawerToggler={drawerToggler}>
      <div className="appointment_info_drawer">
        <div className="appointment_info_drawer_container">
          {/* This is the Share and Cancel Button Container */}
          <div className="appointment_info_drawer_head">
            <div className="appointment_info_drawer_head_container">
              {status === "Pending" && profile_data?.role === "STUDENT" && (
                <>
                  <AiOutlineDelete
                    className="appointment_info_drawer_head_icon"
                    aria-describedby={id}
                    onClick={handleDelete}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    className="appointment_timer_dialog_box_container"
                  >
                    <div className="appointment_timer_dialog_box">
                      Are you sure you want to Delete this Appointment?
                      <div className="appointment_timer_dialog_box_button_container">
                        <Button
                          label="No"
                          variant="primary"
                          buttonClassName="appointment_timer_dialog_box_button"
                          onClick={handleClose}
                          disable={deleting}
                        />

                        <Button
                          label="Yes"
                          variant="danger"
                          buttonClassName="appointment_timer_dialog_box_button"
                          onClick={handleConfirmDelete}
                          loading={deleting}
                          disable={deleting}
                        />
                      </div>
                    </div>
                  </Popover>
                </>
              )}

              <AiOutlineClose
                className="appointment_info_drawer_head_icon"
                onClick={onClose}
              />
            </div>
          </div>

          <div className="appointment_info_drawer_body">
            {/* Appointment Info Heading - TOPIC & COURSE */}
            <div className="appointment_info_drawer_body_heading">
              <div className="heading_color"></div>
              <div className="heading_course_title">
                {topic ?? ""}
                <span>{course ?? ""}</span>
              </div>
            </div>

            {/* Time and Date */}
            <div className="appointment_info_drawer_body_time_and_date">
              <div className="appointment_info_drawer_body_time_and_date_item">
                <MdOutlineCalendarMonth className="appointment_info_drawer_icon" />
                <span>{formatDate(appointmentDate) ?? ""}</span>
              </div>
              <div className="appointment_info_drawer_body_time_and_date_item">
                <AiOutlineClockCircle className="appointment_info_drawer_icon" />
                <span>
                  {appointmentStartTime()}-{appointmentEndTime()}
                </span>
              </div>
            </div>

            {/* Teacher Info */}
            {acceptedBy && (
              <div className="appointment_info_drawer_body_teacher_container">
                <h4 className="appointment_info_drawer_body_teacher_container_heading">
                  Teacher
                </h4>
                <div className="appointment_info_drawer_body_teacher_container_information">
                  <div className="teacher_image">
                    <img />
                  </div>
                  <div className="teacher_info">
                    <h3>
                      {acceptedBy.given_name} {acceptedBy.family_name}
                    </h3>
                    <span className="copy_id_button">
                      <CopyWithToolTip copyItem={acceptedBy.id} />{" "}
                      {acceptedBy.id}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Course Description */}
            <div className="appointment_info_drawer_body_course_description">
              <h3>Comments</h3>
              <p>{comments}</p>
            </div>

            {/* Make Payment */}
            {!paid &&
              status === "Accepted" &&
              profile_data.role === "STUDENT" &&
              createdBy === profile_data.id && (
                <div className="appointment_info_drawer_body_make_payment">
                  <p>
                    Based on the duration of the session which is{" "}
                    {getDuration()} hours your total fee is{" "}
                    <span>NGN{getAmount()}</span>.
                  </p>
                  <Button
                    label="Make Payment"
                    variant="primary"
                    width="40%"
                    onClick={goToCheckout}
                  />
                </div>
              )}

            {!paid &&
              status === "Accepted" &&
              profile_data.role === "TEACHER" && (
                <div className="alert-container">
                  <CustomAlert
                    header="Info"
                    message="Student is yet to complete payment for this appointment."
                  />
                </div>
              )}

            {paid && (
              <div style={{ marginTop: 16 }}>
                {profile_data?.role === "TEACHER" && startShouldShow && (
                  <Button
                    label="Start Now"
                    variant="primary"
                    width="40%"
                    onClick={onStartClick}
                  />
                )}

                {profile_data?.role === "STUDENT" &&
                  active &&
                  startShouldShow && (
                    <Button
                      label="Join Now"
                      variant="primary"
                      width="40%"
                      onClick={onStartClick}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </PopDrawer>
  );
};

export default AppointmentInformationDrawer;
